!function($) {
    "use strict";
    class requestHandler {
        constructor(url, method="GET", data=null, redirect=false) {
            this.url = url;
            this.method = method;
            this.data = data;
            if (this.data){
                this.data = JSON.stringify(this.data);
            };
            this.redirect = redirect;
            return this.run()
        }
        async run() {
            if (window.fetch) {
                let objParams = {
                    method: this.method,
                    redirect: "error",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: this.data
                };
                if (this.redirect) {objParams.redirect = 'follow'};
                switch (this.method) {
                    case "GET":
                    case "POST":
                    break;
                    case "PUT":
                        // objParams.method = 'POST';
                        // this.url += `/?_method=${this.method}`;
                    // break;
                    case "DELETE":
                        objParams.method = "POST";
                        this.url += `/?_method=${this.method}`;
                }
                try {
                    return await fetch(this.url, objParams);
                } catch (error) {
                    alert(error);
                }
            }
        }
    };
    const csrf = $("#csrf").val();
    const theme = {

        init: () => {
            theme.activateRecoveryForm();
            theme.activateLoginForm();
            theme.activateNewPasswordForm();
        },

        activateRecoveryForm: () => {
            if ($('#recoveryPasswordModal') === null ) return;
                const recoveryModal = $("#recoveryPasswordModal");

                recoveryModal.on("shown.bs.modal", function() {
                    $("#emailRecovery").trigger("focus");
                });
                recoveryModal.on("hidden.bs.modal", function() {
                    $("#emailRecovery").val("");
                });

                $("form#recoveryForm").on("submit", async function(event) {
                    // intercept et annule la soumission du formulaire HTML
                    event.preventDefault();
                    // Si les données du formulaire sont valides
                    if (document.getElementById("recoveryForm").checkValidity() == true ) {
                        let data = {
                            _csrf: csrf,
                            emailRecovery: $("#emailRecovery").val()
                        };
                        try {
                            const fetchRequest = await new requestHandler("/recovery-password","POST",data);
                            if (fetchRequest) {
                                let res = await fetchRequest.json().then(function(responce){return responce;});
                                // Envois du mot de passe de réinitialisation ok
                                if (fetchRequest.status == 200){
                                    recoveryModal.modal('hide');
                                };
                                alert(res.message)
                            };
                        } catch (e) {
                            // alert(e);
                            alert("Une erreur a eu lieu, veuillez réactualiser la page puis réessayer. Si le problème persiste, contacter le support. Err: 0x0014")
                        }
                    } else {
                        $("form#recoveryForm").removeClass('needs-validation');
                        $("form#recoveryForm").addClass('was-validated');
                    };
                    return false;
                });
        },

        activateLoginForm: () => {
            if ($('form#loginForm') === null ) return;
            $("form#loginForm").on("submit", async function(event) {
                event.preventDefault();
                if (document.getElementById("loginForm").checkValidity() == true ) {
                    let data = {
                        _csrf: csrf,
                        email: $("#email").val(),
                        password: $("#password").val()
                    };
                    try {
                        const fetchRequest = await new requestHandler("/login","POST",data,true);
                        if (fetchRequest) {
                            if (fetchRequest.status == 200) {
                                return location.assign(window.location.origin + "/admin");
                            };
                            var res = await fetchRequest.json().then(function(responce) {return responce;});
                            alert(res.message);
                        };
                    } catch (e) {
                        // alert(e);
                        alert("Une erreur a eu lieu, veuillez réactualiser la page puis réessayer. Si le problème persiste, contacter le support. Err: 0x0015");
                    };
                } else {
                    $("form#loginForm").removeClass('needs-validation');
                    $("form#loginForm").addClass('was-validated');
                };
                return false;
            });
        },

        activateNewPasswordForm: () => {
            if ($('#changeOwnPassword') === null ) return;
            document.addEventListener("DOMContentLoaded",function(){
                $("#changeOwnPassword").modal('show');
            });
            const $newPasswordModal = $("#changeOwnPassword");
            $newPasswordModal.on("shown.bs.modal", function() {
                $("#password").trigger("focus")
            });
            $newPasswordModal.on("hidden.bs.modal", function() {
                $newPasswordModal.dispose()
            });
            $("form#changeOwnPasswordForm").on("submit", async function(event) {
                event.preventDefault()
                if (document.getElementById("changeOwnPasswordForm").checkValidity() == true) {
                    let data = {
                        _csrf: csrf,
                        password: $("#password").val()
                    };
                    try {
                        const fetchRequest = await new requestHandler("/admin/nouveau-mot-de-passe","PUT",data,true);
                        if (fetchRequest) {
                            var res = await fetchRequest.json().then(function(responce) {return responce;});
                            if (fetchRequest.status == 200) {
                                $newPasswordModal.modal('hide');

                                alert(res.message);
                                return location.assign(window.location.origin + "/login");
                            };
                            alert(res.message);
                        };
                    } catch (e) {
                        // alert(e);
                        alert("Une erreur a eu lieu, veuillez réactualiser la page puis réessayer. Si le problème persiste, contacter le support. Err: 0x0016")
                    };
                } else {
                    $("form#changeOwnPasswordForm").removeClass('needs-validation');
                    $("form#changeOwnPasswordForm").addClass('was-validated');
                };
                return false;
            });
        }
    };

    theme.init()
}(jQuery);